import React, { useEffect } from "react";
import Layout from "./layouts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import MobileMessage from "@Pages/MobileMessage";

function App() {
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div className={`min-h-screen`}>
      {/* <div className={`min-h-screen ${isMobile ? 'backdrop-blur-xl' : ''}`}> */}
      {/* {isMobile && <MobileMessage/>} */}
      <Layout />
      <ToastContainer/>
    </div>
  );
}

export default App;
