const styles = {
    SIGNUP_CONTAINER: {
        border: '1px solid #a2a2a2',
        borderRadius: '16px',
        padding: '1.5rem'
    },
    CONTAINER: {
        backgroundImage: 'url("https://admin.stan.store/assets/img/stanbgb0912c67.svg")'
    }
}

export default styles