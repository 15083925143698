import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const loginApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/order',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        verifyOrder: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/order/verify',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useCreateOrderMutation, useVerifyOrderMutation } = loginApis;
