// import "./App.css";
import SignUpPage from "@Pages/Signup2";
import SignInPage from "@Pages/Login";
import SignUpConnect from "@Pages/ConnectInstagram";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ROUTES } from "@Constants/routes";
import IgConnected from "@Pages/IgConnected";
import Contacts from "@Pages/Contacts";
import ConnectFacebook from "@Pages/ConnectFacebook";
import { useLazyGetProfileQuery } from "@Pages/Profile/apiSlice";
import { useEffect } from "react";
import { ProtectedRoute } from "./ProtectedRoute";
import AutomationList from "@Pages/AutomationList";
import EditAutomation from "@Pages/EditAutomation";
import Error404 from "@Pages/Error404";
import Setting from "@Pages/Setting";
import UserDetails from "@Pages/UserDetails";
import Channels from "@Pages/Channels";
import ScheduledPostList from "@Pages/ScheduledPostList";
import Inbox from "@Pages/Inbox";
import AutomationBuilder from "@Pages/AutomationBuilder";
import EditScheduledPost from "@Pages/ScheduledPostEdit";
import InstagramLogin from "@Pages/InstagramLogin";
import Comments from "@Pages/Comments";
import PaymentOptions from "@Pages/PaymentOption";

function Layout() {

  const [getProfile, { data: getProfileDataResponse }] = useLazyGetProfileQuery();

  useEffect(() => {
    getProfile({})
  
  }, [])

  useEffect(() => {
    if (getProfileDataResponse){
      console.log(getProfileDataResponse);
      
    }
  }, [getProfileDataResponse])
  
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.SIGNUP_PAGE} element={<SignUpPage />}></Route>
          <Route path={ROUTES.LOGIN} element={<SignInPage />}></Route>
          <Route path={ROUTES.ALL} element={<Error404/>}></Route>

          {/* PROTECTED ROUTES */}
          <Route
            path={ROUTES.SIGNUP_CONNECT_PAGE}
            element={<ProtectedRoute Component={SignUpConnect} />}
          ></Route>
          <Route
            path={ROUTES.IG_CONNECT_SUCCESS}
            element={<ProtectedRoute Component={IgConnected} />}
          ></Route>
          <Route path={ROUTES.PAYMENTPAGE} element={<ProtectedRoute Component={PaymentOptions} />}></Route>
          <Route path={ROUTES.INBOX} element={<ProtectedRoute Component={Inbox} />}></Route>
          <Route path={ROUTES.CONTACTS} element={<ProtectedRoute Component={Contacts} />}></Route>
          <Route path={ROUTES.CONNECT_FACEBOOK} element={<ProtectedRoute Component={ConnectFacebook} />}></Route>
          <Route path={ROUTES.AUTOMATIONS} element={<ProtectedRoute Component={AutomationList} />}></Route>
          <Route path={ROUTES.EDIT_AUTOMATIONS} element={<ProtectedRoute Component={EditAutomation} />}></Route>
          <Route path={ROUTES.SETTINGS} element={<ProtectedRoute Component={Setting} />}></Route>
          <Route path={ROUTES.USER_DETAILS} element={<ProtectedRoute Component={UserDetails} />}></Route>
          <Route path={ROUTES.CHANNELS} element={<ProtectedRoute Component={Channels} />}></Route>
          <Route path={ROUTES.SCHEDULED_POST} element={<ProtectedRoute Component={ScheduledPostList} />}></Route>
          <Route path={ROUTES.FLOW_BUILDER} element={<ProtectedRoute Component={AutomationBuilder}/>}></Route>
          <Route path={ROUTES.EDIT_SCHEDULED_POST} element={<ProtectedRoute Component={EditScheduledPost} />}></Route>
          
          <Route path={ROUTES.IG_CONNECT} element={<ProtectedRoute Component={InstagramLogin} />}></Route>
          <Route path={ROUTES.COMMENTS} element={<ProtectedRoute Component={Comments} />}></Route>

          <Route path={ROUTES.BLANK} element={<Navigate to={ROUTES.AUTOMATIONS} replace />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Layout;
