import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";

import { Logo1024PNG, LPLogoWName, Selfie2 } from "../../assets/index";
import { Confetti } from "../../constants/common";

import styles from "./styles";
import LocalStorage from "@Utils/storage";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Constants/routes";


const IgConnected = () => {
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsExploding(true);
    }, Confetti.DELAY);
    setTimeout(() => {
      setIsExploding(false);
    }, Confetti.DURATION);
  }, []);


  const navigate = useNavigate();

  const takeToDashboard = () => {
    LocalStorage.removeItem('ppu')
    LocalStorage.removeItem('igu')
    navigate(ROUTES.PAYMENTPAGE)
  }
  

  return (
    <div className="grid grid-cols-2">
      <div className="h-[100vh] bg-[#EAE8FD] px-[1rem] py-[1rem]">
        <div>
          <p className="font-bold text-2xl">

            <img src={LPLogoWName} className="w-[200px] item-center justify-center" />
          </p>
        </div>
        <div className="mt-[1rem]">
          <img src={Selfie2} className="w-[60%] item-center justify-center" />
          <div className="mt-[4rem]">
            <p className="font-bold text-[48px]">Connect Instagram</p>
            <p className="text-[#787781]">Follow the instruction to connect to instagram, account</p>
          </div>
        </div>
      </div>
      <div className="flex item-center justify-center  align-middle my-auto">
        {/* <div className="absolute right-10 top-10">
            <button className="border-2 border-[#7772FF] font-bold rounded-3xl p-3 text-[14px]">Get Started Free</button>
        </div> */}
        <div className="w-[60%] flex item-center justify-center" style={styles.SIGNUP_CONTAINER}>
          <div>

            <div className="flex-col" style={styles.CONTENT}>
              {isExploding && (
                <ConfettiExplosion
                  force={Confetti.FORCE}
                  duration={Confetti.DURATION}
                  particleCount={Confetti.PARTICLE_COUNT}
                  width={Confetti.WIDTH}
                />
              )}
              <div className="container-msg flex flex-col justify-center items-center my-[2rem]">
                <img style={styles.PROFILE_IMAGE} src={LocalStorage.getItem('ppu') || ''} alt="" />
                <p className="text-center" style={styles.TEXT_BOLD}>
                  Congratulations, <br /> {LocalStorage.getItem('igu') || ''} is connected!
                </p>
              </div>
              <button className="bg-[#887DF2] text-white px-5 rounded-lg py-3 w-[200px]" onClick={() => takeToDashboard()}>Next</button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default IgConnected;
